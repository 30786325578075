import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from "./Header";
import { getAuthHeader } from "./utils";

const BlogPost = () => {
  const location = useLocation();
  const slug = location.pathname.replace('/blog/', '');
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await axios.get(
          `https://api.instacorp.ae/api/blog/${slug}`, 
        );
        
        setBlog(response.data.blog);
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch blog post';
        console.error('Fetch blog error:', error);
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (slug) {
      fetchBlog();
    }

    return () => {
      // Cleanup function
      setBlog(null);
      setError(null);
    };
  }, [slug]); // Changed dependency from id to slug

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex items-center justify-center h-64">
          <div className="text-center text-indigo-600">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600 mx-auto mb-4" />
            Loading...
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-2xl mx-auto text-center">
            <div className="bg-red-50 p-4 rounded-md">
              <h2 className="text-red-800 text-lg font-medium mb-2">Error Loading Blog Post</h2>
              <p className="text-red-600">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-gray-800 text-lg font-medium">Blog post not found</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <article className="max-w-2xl mx-auto bg-white shadow-sm rounded-lg overflow-hidden">
          {blog.featuredImageUrl && (
            <img
              src={blog.featuredImageUrl}
              alt={blog.title}
              className="w-full h-64 object-cover"
            />
          )}
          <div className="px-6 py-8">
            <h1 className="text-4xl font-bold text-gray-800 mb-3">{blog.title}</h1>
            <div className="flex items-center text-gray-500 text-sm mb-6">
              <time dateTime={blog.createdAt}>
                {new Date(blog.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </time>
              {blog.author && (
                <>
                  <span className="mx-2">•</span>
                  <span>{blog.author}</span>
                </>
              )}
              {blog.readingTime && (
                <>
                  <span className="mx-2">•</span>
                  <span>{blog.readingTime} min read</span>
                </>
              )}
            </div>
            <div 
              className="prose lg:prose-xl max-w-none"
              dangerouslySetInnerHTML={{ 
                __html: blog.content 
              }}
            />
            {blog.tags && blog.tags.length > 0 && (
              <div className="mt-8 pt-8 border-t border-gray-200">
                <div className="flex flex-wrap gap-2">
                  {blog.tags.map(tag => (
                    <span 
                      key={tag}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPost;